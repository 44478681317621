import React, { useEffect, useState } from 'react'
import { Row, Col, Radio, Space, Form,Select,Checkbox, Input } from "antd";
import {useHistory} from 'react-router-dom';
import "antd/dist/antd.css";
import TextArea from "rc-textarea";
import { useForm } from 'antd/lib/form/Form';
function Qualification(props: any) {
  const { Option } = Select;
  const history = useHistory();
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [qualificationform] = Form.useForm();
  const valdata:any = sessionStorage.getItem('validation')
  const newdata = JSON.parse(valdata);
  const handlescreenSize=()=>{
    setScreenSize(window.innerWidth)
  }

  useEffect(() => {
    if(!newdata?.proceed){
      history.push("/background-info")
    }
  }, [])
  useEffect(() => {
      handlescreenSize();
    window.addEventListener("resize", handlescreenSize);
   return ()=>{
      window.removeEventListener("resize", handlescreenSize)
    }
  }, [window.innerWidth])

  const next=()=>{
    console.log("End of Qualification Page")
  }
   return (
    // <div className="container-result">
    //   <Col span={24} style={{ padding: "0px" }}>
      <Row gutter={24} className="container-result-main">
      <Col span={screenSize<550?24:screenSize<=1024?18:22} className="side-result-container">
        <Row>
          <Col span={screenSize <= 1024 ? 22 : 18}>
            <div className="step-text-main">
            Background Information...
            </div>
          </Col>
        </Row>
        <Col className="personalize-form qualification-form">
          <Form form={qualificationform} layout="vertical">
            <Row>
              <Col span={24}>
                <Form.Item
                  name="parentname"
                  label="The #1 thing you need help with at the moment?"
                >
                    <Select>
                    <Option key="12" value="123" >
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eligendi molestias, optio deserunt doloribus recusandae illum!
                    </Option>
                    <Option key="12" value="123">
                        wertyuiowwertyui ertyui Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eligendi molestias, optio deserunt doloribus recusandae illum!
                    </Option>
              </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
            <Col span={24}>
                <Form.Item
                  name="sitatution"
                  label="Choose the one that best describes your situation?"
                  className="qualification-form-radio"
                >
                    <Radio.Group >
                        <Space direction="vertical">
                            <Radio value={1}> Single parent - not employed</Radio>
                            <Radio value={2}> Single parent - working part / full time</Radio>
                            <Radio value={3}> Co-parent (only one of us is working)</Radio>
                            <Radio value={4}> Co-parent (both of us working)</Radio>
                            <Radio value={5}> Co-parent (neither of us are employed)</Radio>
                            <Radio value={6}> Others (temporary carer, grandparent, etc)</Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
            <Col span={24}>
                <Form.Item
                  name="fundingoptions"
                  label="Autism 360 is a one-on-one service that’s 100% tailored to your child’s needs. If you feel this is a fit, are you willing to invest time and money? (We have funding options available) *"
                  className="qualification-form-radio"
                >
                    <Radio.Group>
                            <Radio value={1}>Yes, I understand this needs time and money</Radio>
                            <Radio value={2}>No, I’m not looking to invest at the moment</Radio>
                    </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item
                    name="supportchild"
                    label="What do you have in place at the moment to support your child? *"
                    className="qualification-form-textarea"
                    >
                        <TextArea rows={6}></TextArea>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item
                    name="sharethings"
                    label="Do you like how things are going at the moment? (Please share) *"
                    className="qualification-form-textarea"
                    >
                        <TextArea rows={6}></TextArea>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item
                    name="sharethings"
                    label="If you need your spouse or partner, please book a time that they can be present?"
                    className="qualification-form-checkbox"
                    >
                        <Checkbox>Okay, got it</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
          </Form>
          </Col>
      
      {
              screenSize<1024?
              (<Col>
                <Row>
                  <div className="footer-text-qualification-form">Great, it’s your time to start <span>consciously creating your child's future</span></div>
                </Row>
                <Row style={{float:"right"}}>
                  <div onClick={next} className="button-getting-started mobile-view-button"><button className="getting-start-button">Schedule Call</button>  </div>
                </Row>
              </Col>):
              (<Row>
                <Col span={10}>
                  <div className="footer-text-qualification-form">Great, it’s your time to start <span>consciously creating your child's future</span></div>
                </Col>
                  <Col span={14}>
                  <div onClick={next} className="button-getting-started"><button className="getting-start-button">Schedule Call</button>  </div>
              </Col>
              </Row>)
          //   :(
          //     <Row>
          //   {current < steps.length-1 && (
          //       <Col span={24}>
          //       <div onClick={next} className={`button-getting-started`}><button className="getting-start-button">Schedule Call</button>  </div>
          //   </Col>
          // )}
          // </Row>
            // )
}
</Col>
    </Row>
  );
}

export default Qualification;
