import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Row, Col } from 'antd';

import { pathways } from '../../utils/consts/common';
import { setPathway } from '../../redux/slices/assessSlice';

function Pathways() {
    const history = useHistory();
    const dispatch = useDispatch();

    const handleClick = (one: Pathway) => {
        if(!one.tableName)
            return;
        else {
            dispatch(setPathway(one));
            history.push('/member');
        }
    }
    const contentcard = (one: Pathway, index: number) => {
        return (
            <Col
                key={`pathway_card_${index}`}
                sm={24}
                md={7}
                className={`col-category-background-${one.id} col-contentcard-cards`}
                onClick={() => handleClick(one)}
            >
                <Row>
                    <div className="circle120">
                        <img src={one.image} alt="Loading..."/>
                    </div>
                </Row>
                <Row className="heading-row-category">
                    <div className="heading">{ one.title }</div>
                </Row>
            </Col>
        )
    }

    return (
        <div className="container">
            <Col span={24} className="mobile-view-container">
                <div className="step-text-main">
                    <span>What do you need MOST help with?</span>
                </div>
                <Row className="row-content-cards-display">
                    { pathways.map((one, index) => contentcard(one, index)) }
                </Row>
            </Col>
        </div>
    );
}

export default Pathways;
