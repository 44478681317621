import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGoals, getLevel, getSkills } from "../../api";
import { AssessmentType, setIsLoading } from "../../redux/slices/assessSlice";
import { RootState } from "../../redux/store";

const getRecommendGoals = (questions: Array<any>, goals: Array<any>) => {
    const recommendGoals: any = {};
    const allGoals = [ ...goals ].map(one => one.fields);
    allGoals.sort((a, b) => {
        return a.lookupRef > b.lookupRef? 1: (a.lookupRef === b.lookupRef? 0: -1);
    });

    questions.forEach(qs => {
        const selectedRes = qs.qResponse.find((res: any) => res.fields.responseRef === qs.selectedResponse);
        const linkedGoal = selectedRes.fields.linkedGoal;
        if(!linkedGoal)
            return;
        let result = allGoals.find(one => one.lookupRef === linkedGoal) ?? allGoals.find(one => one.lookupRef > linkedGoal);
        
        if(result && result?.lookupText)
            recommendGoals[result.lookupRef] = result;
    });

    return Object.values(recommendGoals);
}

export const useSummary = () => {
    const dispatch = useDispatch();
    const { questions, pathway } = useSelector<RootState, AssessmentType>(state => state.assessment);
    const [assessmentLevel, setAssessmentLevel] = useState<any>(null);
    const [linkedGoals, setLinkedGoals] = useState<any>([]);
    const [recommendGoals, setRecommendGoals] = useState<any>([]);

    useEffect(() => {
        if(!pathway)
            return;
        getGoals({ tableName: pathway.tableName }).then((res: any) => {
            if(!res.length)
                return;
            setLinkedGoals(getRecommendGoals(questions, res));
        })
    }, []);

    useEffect(() => {
        if(!pathway || !linkedGoals.length)
            return;
        
        const lastQuestion = questions[questions.length - 1];
        const segmentData = {
            stage: lastQuestion.fields.questionRef.split(".")[0].substring(1),
            level: lastQuestion.fields.segmentIdentifier
        }
        dispatch(setIsLoading(true))
        Promise.all([
            getLevel({ tableName: pathway.tableName, lookupRef: segmentData.level }),
            getSkills({ tableName: pathway.tableName, goalRefs: linkedGoals.map((one: AIRTABLE_Lookup) => one.lookupRef)})
        ]).then(res => {
            let [ level, skills ] = res;
            const lookupText = !level.length? '': level[0].fields.lookupText;
            skills = !skills.length? []: skills.map((one: any) => one.fields)
            const nRecommendGoals = linkedGoals.map((one: AIRTABLE_Lookup) => {
                const relatedSkills = skills.filter((skill: any) => skill.goalRef === one.lookupRef)
                return { ...one, skills: relatedSkills };
            });
            setAssessmentLevel({
                ...segmentData,
                level: {
                    lookupRef: segmentData.level,
                    lookupText
                }
            });
            setRecommendGoals(nRecommendGoals);
            dispatch(setIsLoading(false));
        });
    }, [linkedGoals]);

    return [ recommendGoals, assessmentLevel ];
}