import React from 'react';

const UnderDevelopmentComponent: React.FC = () => {
    return (
        <div>
            {/* // <div className="site-layout-background">
            //     <Spin spinning={isLoader}>
            //         <div >
            //             <Title>Webpage is under development</Title>
            //         </div>
            //     </Spin>
            // </div> */}
                {window.history.back()}
                <h1></h1>
        </div>
    )
}

export default UnderDevelopmentComponent;
