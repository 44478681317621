import { configureStore } from "@reduxjs/toolkit";

import assessmentReducer, { initialState as assessInitialState } from "./slices/assessSlice";

const stateFromCookie = localStorage.getItem('state');
const initialState = stateFromCookie? JSON.parse(stateFromCookie): {
    assessment: assessInitialState
}

const store = configureStore({ 
    preloadedState: initialState,
    reducer: {
        assessment: assessmentReducer
    }
});

let timerId: string = '';
store.subscribe(() => {
    const state = store.getState();
    localStorage.setItem('state', JSON.stringify(state));
    console.log('state', state, JSON.stringify(state));
    console.log(localStorage.getItem('state'))

    clearTimeout(timerId);
    setTimeout(() => {
        localStorage.removeItem('state');
    }, 1 * 60 * 60 * 1000);
})
export type RootState = ReturnType<typeof store.getState>;
export default store;