import React from 'react'
import { Row, Col } from "antd";
import assignmentsvg from "../../images/svg/rapidassessment.svg"
import smartgoalsvg from "../../images/svg/smartgoal.svg"
import programsvg from "../../images/svg/program.svg"
import clocklogo from "../../images/svg/clockicon.svg"; 

function Information(props: any) {
  const contentcard = (image: any, srno: string, heading: string, content: string) => {
    return (
      <Row gutter={14} className="row-contentcard information-contant-card">
        <Col span={4} style={{ margin:"auto",padding:"15px" }}> {/* handling responsive view by increasing col size  */}
          <img className="circle-img-info" src={image} alt="Loading..." />
        </Col>
        <Col span={20}>
          <div className="Heading">
            <span>{ srno }</span>
            <div style={{ flex: 7 }}>{heading}</div>
            <div className="clocklogopic" >
              <img className="clocklogo" src={clocklogo} alt="clock"/>
            </div>
            <div style={{ flex: "none", marginLeft: "auto", color: "#EE2E7F" }}>(1 min)</div>
          </div>
          <div className="inner-text">{ content }</div>
        </Col>
      </Row>
    );
  };
  
  return (
    <div className="container">
      <p className="step-text-main">
        Let’s start... <br/> Here’s whats about to happen
      </p>
      <div>
        {contentcard(
          assignmentsvg,
          "01",
          "Rapid Assesment",
          "lorem24  Lorem ipsum dolor sit amet, consectetur adipiscing elit  Lorem ipsum dolor sit amet, consectetur adipiscing elit  Lorem ipsum dolor sit amet, consectetur adipiscing elit  Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        )}
        {contentcard(
          smartgoalsvg,
          "02",
          "Choose a SMART Goal",
          "lorem24  Lorem ipsum dolor sit amet, consectetur adipiscing elit  Lorem ipsum dolor sit amet, consectetur adipiscing elit  Lorem ipsum dolor sit amet, consectetur adipiscing elit  Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        )}
        {contentcard(
          programsvg,
          "03",
          "Create a Tailored Program",
          "lorem24  Lorem ipsum dolor sit amet, consectetur adipiscing elit  Lorem ipsum dolor sit amet, consectetur adipiscing elit  Lorem ipsum dolor sit amet, consectetur adipiscing elit  Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        )}
      </div>
    </div>
  );
}

export default Information;
