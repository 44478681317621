import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Col, Row, Spin } from "antd";

import UserInfo from "./components/UserInfo";
import WelcomeScreen from "./components/WelcomeScreen";
import Result from "./components/Result";
import ProceedPage from "./components/steps/ProceedPage";
import Pathways from "./components/steps/Pathways";
import ChildForm from "./components/steps/ChildForm";
import Survey from "./components/wizard/Survey";
import ParentForm from "./components/steps/ParentForm";
import Qualification from "./components/steps/Qualification";
import UnderDevelopmentComponent from "./components/WebPageunderDevelopment";
import LogoImage from "../src/images/Autism360_Logo.png";
import { RootState } from "./redux/store";
import { AssessmentType } from "./redux/slices/assessSlice";

export default function App(props : any) {
  const { isLoading } = useSelector<RootState, AssessmentType>(state => state.assessment);
  return (
    <Spin spinning={isLoading}>
      <Row gutter={24} className="container-main">
        <Col span={10}>
          <div className="wire-logo-div">
            <img src={LogoImage} alt=""/>
          </div>
        </Col>
        <Col sm={24} md={14} className="side-container">
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={WelcomeScreen} />
              <Route exact path="/pathway" component={Pathways} />
              <Route exact path="/member" component={ChildForm} /> 
              <Route exact path="/assessment" component={Survey} />
              <Route exact path="/parent-form" component={ParentForm} />
              <Route exact path="/result" component={Result} />
              <Route exact path="/user" component={UserInfo} />
              <Route exact path="/background-info" component={ProceedPage} />
              <Route exact path ="/qualification" component={Qualification} />
              <Route component={UnderDevelopmentComponent} />
            </Switch>
          </BrowserRouter>
        </Col>
      </Row>
    </Spin>
  )
}