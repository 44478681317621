import React from 'react'
import {useState,useEffect} from 'react'
import {Form,Select,Col,Input,Button} from 'antd'
import 'antd/dist/antd.css';
import countryCode from '.././countryCode.json';
import countryName from '.././country.json'


const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 16 },
  };
const {Option}=Select

function UserInfo() {
    const [activeForm, setActiveForm] = useState({})
    const [form]=Form.useForm();
    
      useEffect(() => {
        fetch(
          "http://www.geoplugin.net/json.gp"
        )
          .then((response) => response.json())
          .then((data) => {
              const geodata={
                  city:data.geoplugin_city,
                  country:data.geoplugin_countryName,
                  countrycode:countryCode.filter((val,index)=>val.code===data.geoplugin_countryCode)[0].dial_code
              }
              form.setFieldsValue(geodata)
              setActiveForm(geodata)
            });
      },[]);
    const onHandleChange = (e:any, name:string) => {
        const { value } = e.target;
        setActiveForm({ ...activeForm, [name]: value });
    };
    const onHandleCountryChange=(value:any)=>{
        setActiveForm({...activeForm,"country":value})
        if(countryCode.filter((data,index)=>data.name===value)[0]){
            const code = countryCode.filter((data,index)=>data.name===value)[0].dial_code
            form.setFieldsValue({countrycode:code})
            setActiveForm({...activeForm,"countrycode":code})
        }
    }
    const onHandleCodeChange=(value:any)=>{
        setActiveForm({...activeForm,"countrycode":value});
    }
    const Submit=()=>{
        localStorage.setItem('userInfo',JSON.stringify(activeForm))
        form.resetFields();
    }

    const prefixSelector = (
        <Form.Item name="countrycode" noStyle>
          <Select style={{ width: 100 }} onSelect={onHandleCodeChange}>
            {countryCode.map((val,index)=>
            <Option value={val.dial_code} key={index}>{val.dial_code}</Option>
            )}
          </Select>
        </Form.Item>
      );
    return (
        <div className="user-form-main">
            <Col span={9}>
                <Form
                    {...formItemLayout}
                    form={form}
                    name="register"
                    onFinish={Submit}
                    className="manage-form-style"
                    scrollToFirstError
                    >
                            <Form.Item 
                                name="firstname"
                                label="FirstName"
                                rules={[
                                    {required:true,message:"Please input your FirstName"},
                                    {pattern:/^(?!\s)[a-zA-Z\s]+$/,message:'Name must contain alphabets'}
                                ]}
                            >
                                <Input name="firstName" onChange={(e) => onHandleChange(e, 'firstName')} />
                            </Form.Item>
                            <Form.Item 
                                name="lastname"
                                label="LastName"
                                rules={[
                                    {pattern:/^(?!\s)[a-zA-Z\s]+$/,message:'Name must contain alphabets'}
                                ]}
                            >
                                <Input name="lastname" onChange={(e) => onHandleChange(e, 'lastname')} />
                            </Form.Item>
                    
                            <Form.Item
                                name="email"
                                label="E-mail"
                                rules={[
                                {
                                    type: 'email',
                                    message: 'The input is not valid E-mail!',
                                },
                                {
                                    required: true,
                                    message: 'Please input your E-mail!',
                                },
                                ]}
                            >
                                <Input name="email" onChange={(e) => onHandleChange(e, 'email')} />
                            </Form.Item>
                                <Form.Item
                                    name="childfirstname"
                                    label="Child FirstName"
                                    rules={[
                                        {required:true,message:"Please input Child`s FirstName"},
                                        {pattern:/^(?!\s)[a-zA-Z\s]+$/,message:'Name must contain alphabets'}
                                    ]}
                                >
                                    <Input name="childfirstName" onChange={(e) => onHandleChange(e, 'childfirstName')} />
                                </Form.Item>
                            <Form.Item
                                name="childlastname"
                                label="Child LastName"
                                rules={[
                                    {pattern:/^(?!\s)[a-zA-z\s]+$/,message:"Name must contain alphabets"}
                                ]}
                            >
                                <Input name="Childlastname" onChange={(e)=>onHandleChange(e,'childlastname')}/>
                            </Form.Item>
                            <Form.Item
                                name="Phonenumber"
                                label="Phone Number"
                                rules={[{ required: true, message: 'Please input your phone number!' },
                                        { pattern:/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,message:'Please input valid Phone Number'}
                                ]}
                            >
                                <Input maxLength={10} name="Phonenumber" onChange={(e)=>onHandleChange(e,'Phonenumber')} addonBefore={prefixSelector} style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item
                                name="city"
                                label="City"
                                rules={[{required:true,message:"Please Select City"}]}
                            >
                                <Input name="city" onChange={(e)=>onHandleChange(e,'city')}/>
                                 {/* <Select defaultValue="Mohali"> */}
                                    {/* // <Option value="Mohali">Mohali</Option> */}
                                    {/* <Option value="Chandigarh">Chandigarh</Option> */}
                                    {/* <Option value="IDDD">IDDD</Option> */}
                                    {/* <Option value="SDDD">SDDD</Option> */}
                                {/* </Select> */} 
                            </Form.Item>
                            <Form.Item
                                name="country"
                                label="Country"
                                rules={[{required:true,message:"Please Select Country"}]}
                            >
                                <Select onSelect={onHandleCountryChange}>
                                {countryName.map((value,index)=>
                                    <Option value={value.name}>{value.name}</Option>
                                )}
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <Button htmlType="submit" type="primary" className="submit-button">Submit</Button>
                            </Form.Item>
                </Form>
            </Col>
        </div>
    )
}

export default UserInfo
