import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, Space, Tooltip, Popover, Checkbox, Collapse, CollapseProps } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { AssessmentType } from "../../redux/slices/assessSlice";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";

const GoalList = (props: any) => {
  const { value, recommendGoals, handleChange } = props;
  const [ activeCollapseKeys, setActiveCollapseKeys ] = useState<string[]>([]);
  
  useEffect(() => {
    const collapseKeys = value.map((goalRef: string) => {
      const idx = recommendGoals.findIndex((one: any) => one.lookupRef === goalRef);
      return `goal_collapse_${idx}`;
    });
    setActiveCollapseKeys(collapseKeys);
  }, [value])
 
  return (
    <Checkbox.Group onChange={handleChange} value={value}>
      <Space direction="vertical">
        <Collapse className="goal-collapse-container" activeKey={activeCollapseKeys}>
          {
            recommendGoals?.map((data: any, index: number) => {
              const headerComponent = (
                <Checkbox value={data.lookupRef}>
                  { data?.lookupText }
          
                  { data?.notes && 
                    <Tooltip
                      className="notes-tooltip"
                      placement="right"
                      title={data?.notes}
                    >
                      <sup>
                        <i className="fa fa-info-circle radio-info-icon" aria-hidden="true"></i>
                      </sup>
                    </Tooltip>
                  }
                </Checkbox>
              );
              return (
                <CollapsePanel header={headerComponent} key={`goal_collapse_${index}`}>
                  <ul>
                    {
                      data.skills.map((one: AIRTABLE_Skills) => <li>{ one.skillDescription }</li>)
                    }
                  </ul>
                </CollapsePanel>
              )
            })
          }
        </Collapse>
      </Space>
    </Checkbox.Group>
  )
}

export default function Assessment(props: any) {
  const { assessmentLevel, recommendGoals, validationText, setValidationText, value, setValue } = props;
  const { childInfo } = useSelector<RootState, AssessmentType>(state => state.assessment);

  const handleChange = (list: CheckboxValueType[]) => {
    setValidationText("");
    setValue(list);
  };
  let hoverContent = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis porro tenetur neque itaque perferendis, vero ipsam tempora consequuntur minus praesentium officia delectus eum";

  return (
    <div className="container-result">
      <Col span={24} style={{ padding: "0px" }}>
        <Row>
          <Col span={24}>
            <div className="step-text-main">
              Based on your inputs here’s our preliminary assessment
            </div>
          </Col>
        </Row>
        <Row gutter={24} className="result-question-container">
          <Row className="link-text-result">
            <Popover
              className="popover-assessment"
              placement="bottomRight"
              content={hoverContent}
              trigger="click"
            >
              <span>Disagree?</span>
            </Popover>
          </Row>
          <Col span={24} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <div
              className="result-subheading-container"
              style={{ backgroundColor: "#F5F6F8", borderRadius: "10px" }}
            >
              <span style={{ color: "#50B1E1", fontWeight: 400 }}>
                Stage {assessmentLevel?.stage} :
              </span>{" "}
              <span style={{ textDecoration: "underline" }}>
                Level {assessmentLevel?.level.lookupRef}
              </span>
              <br />
              <div style={{ margin: "20px 20px 30px 0px", fontWeight: 600 }}>
                {assessmentLevel?.level.lookupText ?? ""}
              </div>
            </div>
          </Col>
          <Row className="link-text-result">
            {/* <span onClick={()=>setlearnmodalopen(true)}>Learn how this is calculated</span> */}
            <Popover
              className="popover-assessment"
              placement="bottomRight"
              content={hoverContent}
              trigger="click"
            >
              <span>Learn how this is calculated</span>
            </Popover>
          </Row>
          <Col span={24} className="ques-result-style">
            <div>
              Based on the outcome of this assessment, here are some Goals that
              you could possibly help { childInfo?.childName } achieve over the next 90 days in
              the program. Choose the one that applies most.
            </div>
          </Col>
          <Col span={24} className="checkbox-result-options">
            <div className="textbody">
              <GoalList value={value} recommendGoals={recommendGoals} handleChange={handleChange} />
            </div>
          </Col>
        </Row>
        <span className="question-validation-span">{ validationText }</span>
      </Col>
    </div>
  );
}
