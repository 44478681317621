import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import {Row, Col, Progress, notification  } from 'antd'

import QuestionSlider from './questionSlider';
import { getQuestion, getResponse } from "../../api/index"
import { RootState } from '../../redux/store';
import { addQuestion, AssessmentType, removeQuestion, setIsLoading } from '../../redux/slices/assessSlice';

export default function Survey(props: any) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { childInfo, pathway, questions } = useSelector<RootState, AssessmentType>(state => state.assessment);

  const [api, contextHolder] = notification.useNotification();
  const [validationMessage, setValidationMessage] = useState("");

  useEffect(() => {
    if(!childInfo) {
      history.push('/member')
      return
    }
    if(!questions.length) {
      dispatch(setIsLoading(true));
      fetchQuestion("S1.Q1");
    }
  },[]);

  const fetchQuestion = (questionRef: string) => {
    if(!pathway)
      return;
    Promise.all([ getQuestion({ tableName: pathway.tableName, questionRef }), getResponse({ tableName: pathway?.tableName, questionRef }) ]).then(res => {
      let [ question, qResponse ] = res;
      dispatch(setIsLoading(false));
      if(!question.length) {
        api.error({
          message: "The next question isn't available. Please contact development team!"
        });
        return;
      }
      else if(!qResponse.length) {
        api.error({
          message: "No responses available. Please cnotact development team!"
        });
        return;
      }
      dispatch(addQuestion({ ...question[0], qResponse }));
    }).catch(err =>{
      console.log("error.response", err.response, " err: ", err);
      dispatch(setIsLoading(false));
      if(err.response?.status === 429) { // too many requests
        api.error({
          message: "Too many requests, please try again after 30 minutes."
        });
      }
      if(err.response?.data.status === 'sessionTimeout') {
        api.error({
          message: "Session timeout (30 mins). Please restart the assessment program."
        });
      }
      if(err.response?.data.status === 'invalidSignature') {
        api.error({
          message: "Invalid Signature URL."
        });
      }
      if(err.response?.data.status === 'invalidRecaptcha') {
        api.error({
          message: "Invalid Recaptcha or Recaptcha not exist."
        });
      }
      else
        api.error({
          message: "Please refresh and try again."
        })
      setTimeout(() => history.push('/'), 3000);
    });
  }
  const nextSubstepEvt = () => {
    const curQuestion = questions[questions.length - 1];
    if (!curQuestion.selectedResponse) {
      setValidationMessage("Please select an answer.")
      return;
    }
    const selectedResponse = curQuestion.qResponse.find((one: any) => one.fields.responseRef === curQuestion.selectedResponse);
    const nextQuestionRef  =selectedResponse?.fields.nextQuestionRef;
    if (nextQuestionRef === "Null"){
      finishEvt();
      return;
    }
    dispatch(setIsLoading(true));
    fetchQuestion(nextQuestionRef);
  };

  const prevSubstepEvt = () => {
    dispatch(removeQuestion({}));
    if(questions.length === 1){
      history.push("/member");
      return;
    }
    setValidationMessage("");
  }
  const finishEvt = () => {
    history.push(`/parent-form`)
  }

  return (
    <div className="container">
      { contextHolder }
      <Col span={24} style={{ padding: "0px" }}>
        <div className="step-text-main">
          { pathway?.title } assessment development continuuom.
        </div>
        <QuestionSlider
          setValidationMessage={setValidationMessage}
          validationMessage={validationMessage}
        />
      </Col>
      <Row className="button-footer">
        <Col span={4}>
          <div onClick={prevSubstepEvt} className="previous-button">
            <a> Previous </a>
          </div>
        </Col>
        <Col
          span={8}
          style={{ display: questions.length > 1? "flex" : "block" }}
        >
          <Progress
            strokeColor="#FCB333"
            showInfo={false}
            width={400}
            percent={33.33 * 2}
            steps={3}
          ></Progress>
        </Col>
        <Col span={9} className="start-wizard-footer">
          <div className="button-getting-launched">
            <button className="getting-launched-button step-next-button" onClick={nextSubstepEvt}>Next</button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

