import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Select, Input, Progress } from "antd";

import { RootState } from '../../redux/store';
import { AssessmentType, setChildInfo } from '../../redux/slices/assessSlice';
import { getSignature } from '../../api';

export default function ChildForm(props: any) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathway, childInfo } = useSelector<RootState, AssessmentType>(state => state.assessment);

  const [ childForm ] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    if(!pathway)
      history.push("/");
  }, []);

  useEffect(() => {
    if(!childInfo)
      return;
    childForm.setFieldsValue({ ...childInfo });
  }, [ childInfo ])


  const submit = async () => {
    try {
      const data = await childForm.validateFields();

      const signatureData: SIGNATURE = await getSignature();
      localStorage.setItem('signatureData', JSON.stringify({ ...signatureData }));
      dispatch(setChildInfo(data));
      history.push("/assessment");
    } catch (err) {
      console.log("error", err);
    }
  };

  return (
    <div className="personalize-form-container">
      <Col span={24}>
        <div className="step-text-main">Let’s personalize this...</div>
        <div className="personalize-form-text">
          Questions will depend on your child’s current abilities. Please
          choose carefully as your response to the current question will
          determine the next one.
          <span> How it works?</span>
        </div>
        <Form className="personalize-form" onFinish={submit} form={childForm} layout="vertical">
          <Row>
            <Col span={24}>
              <Form.Item
                name="childName"
                label="Child Name"
                rules={[
                  {
                    required: true,
                    message: "Please input your Child's Name",
                  },
                  {
                    pattern: /^(?!\s)[a-zA-Z\s]+$/,
                    message: "Name must contain alphabets",
                  },
                ]}
              >
                <Input
                  name="childName"
                  defaultValue={childForm.getFieldValue('childName')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="personalize-form-age-row">
            <Col span={12} className="personalize-form-age-col">
              <Form.Item
                name="age"
                label="Age"
                rules={[
                  {
                    pattern: /^(0?[1-9]|[1-4][0-9]|50|51|52|53|54|55)$/,
                    message: "please enter age 1 to 55",
                  },
                  { required: true, message: "Please enter age" },
                ]}
              >
                <Input
                  name="age"
                  maxLength={3}
                  defaultValue={childForm.getFieldValue('age')}
                />
              </Form.Item>
            </Col>
            <Col
              span={12}
              className="personalize-form-gender-col gender-select-input"
            >
              <Form.Item
                name="gender"
                label="Gender"
                rules={[{ required: true, message: "Please select gender" }]}
              >
                <Select
                  defaultValue={childForm.getFieldValue('gender')}
                >
                  <Option key="1" value="Male">
                    Male
                  </Option>
                  <Option key="2" value="Female">
                    Female
                  </Option>
                  <Option key="3" value="Unspecified">
                    Unspecified
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
      <Row className="button-footer">
        <Col span={12}>
          <Progress
            strokeColor="#FCB333"
            showInfo={false}
            width={400}
            percent={33.33 * 1}
            steps={3}
          ></Progress>
        </Col>
        <Col span={12} className="start-wizard-footer">
          <div className="button-getting-launched">
            <button className="getting-launched-button" onClick={submit}>Launch Assessment</button>
          </div>
        </Col>
      </Row>
    </div>
  );
}
