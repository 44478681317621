import { createSlice } from "@reduxjs/toolkit";

export const initialState: AssessmentType = {
    isLoading: false,
    pathway: null,
    childInfo: null,
    questions: [],
    parentInfo: null,
};
export interface AssessmentType {
    isLoading: boolean;
    pathway: Pathway | null;
    childInfo: ChildInfo | any;
    questions: Array<any>;
    parentInfo: ParentInfo | any;
}

const assessmentSlice = createSlice({
    name: "assessment",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setPathway: (state, action) => {
            state.pathway = action.payload;
        },
        setChildInfo: (state, action) => {
            state.childInfo = action.payload;
        },
        addQuestion: (state, action) => {
            state.questions.push(action.payload);
        },
        removeQuestion: (state, action) => {
            state.questions.pop();
        },
        addAnswer: (state, action) => {
            state.questions[state.questions.length - 1].selectedResponse = action.payload;
        },
        setGeoData: (state, action) => {
            state.parentInfo = { geo: action.payload };
        },
        addParentInfo: (state, action) => {
            state.parentInfo = { ...state.parentInfo, ...action.payload }
        },
        addMemberId: (state, action) => {
            state.childInfo = { ...state.childInfo, memberId: action.payload };
        }
    }
});

export const { setIsLoading, setPathway, setChildInfo, addQuestion, removeQuestion, addAnswer,
    setGeoData, addParentInfo, addMemberId } = assessmentSlice.actions;
export default assessmentSlice.reducer;