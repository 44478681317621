import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {Row, Col, Radio, Space } from "antd";
import { RootState } from '../../redux/store';
import { addAnswer, AssessmentType } from '../../redux/slices/assessSlice';

const QuestionSlider = (props: any) => {
  const { validationMessage, setValidationMessage } = props
  const dispatch = useDispatch();
  const { questions } = useSelector<RootState, AssessmentType>(state => state.assessment);
  
console.log("question =>>>>>", questions);  
  const handleChange = (e: any) => {
    setValidationMessage("");
    dispatch(addAnswer(e.target.value));
  }
  const renderQuestion = () => {
    if(!questions.length)
      return;
    const curQuestion = questions[questions.length - 1];

    return (
      <Row gutter={24} className="question-container">
        <Col span={24} className="question-image-container">
          {
            curQuestion?.fields.attachment? <img src={curQuestion.fields.attachment[0].url} alt="Loading..." />: null
          }
        </Col>
        <Col span={24} className="ques-style">
          <div>{ curQuestion?.fields.question }</div>
        </Col>
        <Col span={24}>
          <div className="textbody">
            <Radio.Group
              name={`radiogroup-${curQuestion?.id}`}
              value={curQuestion?.selectedResponse}
              onChange={handleChange}
            >
              <Space direction="vertical">
                  { curQuestion?.qResponse.map((data: any, index: number) => {
                      return (
                        <Radio
                          key={`response_${index}`}
                          value={data.fields.responseRef} 
                          checked={curQuestion.selectedResponse === data.fields.responseRef}
                        >
                          {data.fields.response}
                        </Radio>
                      );
                    })}
              </Space>
            </Radio.Group>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <div className="steps-content" style={{ minHeight: "320px" }}>
        { renderQuestion() }
        <span className="question-validation-span">{ validationMessage }</span>
	    </div>
    </>
  );
};

export default QuestionSlider;