import VerbalImg from "../../images/svg/verbal.svg";
import EmotionalImg from "../../images/svg/emotional.svg";
import SocialImg from "../../images/svg/social.svg";
import TrainingImg from "../../images/svg/training.svg";
import SensoryImg from "../../images/svg/sensory.svg";
import LearningImg from "../../images/svg/learning.svg";

export const pathways = [
    { id: "p_verbal", tableName: "Communication", title: "Verbal Communication", image: VerbalImg },
    { id: "p_regulation", tableName: "Regulation", title: "Emotional Skills", image: EmotionalImg },
    { id: "p_playpeople", tableName: "PlayPeople", title: "Play People Skills", image: SocialImg },
    { id: "p_playobject", tableName: "PlayObject", title: "Play Object Skills", image: SocialImg },
    { id: "p_training", tableName: "", title: "Potty Training", image: TrainingImg },
    { id: "p_sensory", tableName: "", title: "Sensory Needs", image: SensoryImg },
    { id: "p_learning", tableName: "", title: "Learning & Intellectual", image: LearningImg },
];