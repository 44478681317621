import { useHistory } from "react-router-dom";
import InformationWireframe from "./steps/InformationWireframe";

function WelcomeScreen() {
  const history = useHistory();

  const handleClick = () => {
    history.push("/pathway");
  }

  return (
    <div className="px-3">
      <InformationWireframe />
      <div className="button-getting-started">
        <button className="getting-start-button" onClick={handleClick}>Let's Start</button>
      </div>
    </div>
  );
}

export default WelcomeScreen;
