import { executeRecaptcha } from '../utils/recaptcha';
import { apiGet, apiPost } from './api';

const GEO_API_URL: any = process.env.REACT_APP_GEO_API_URL;
const API_URL: any = process.env.REACT_APP_API_URL;

export const getGeoData = () => fetch(GEO_API_URL).then(res => res.json());

interface QuestionRequest {
    tableName: string;
    questionRef: string;
}
export const getQuestion = async (params: QuestionRequest) => apiGet(`${API_URL}/assessment/v2/question`, withSignature(params), await withRecaptcha());

interface ResponseRequest {
    tableName: string;
    questionRef: string;
}
export const getResponse = async (params: ResponseRequest) => apiGet(`${API_URL}/assessment/v2/response`, withSignature(params), await withRecaptcha());

interface LevelRequest {
    tableName: string;
    lookupRef: string;
}
export const getLevel = (params: LevelRequest) => apiGet(`${API_URL}/assessment/v2/level`, withSignature(params));

interface GoalRequest {
    tableName: string;
}
export const getGoals = (params: GoalRequest) => apiGet(`${API_URL}/assessment/v2/goal`, withSignature(params));

interface SkillRequest {
    tableName: string;
    goalRefs: string[];
}
export const getSkills = (params: SkillRequest) => apiGet(`${API_URL}/assessment/v2/skill`, withSignature({ ...params, goalRefs: JSON.stringify(params.goalRefs) }));

export const createUserApi = async (params: any) => apiPost(`${API_URL}/assessment/v2/user`, withSignature(params), await withRecaptcha());

export const createGoalApi = async (params: any) => apiPost(`${API_URL}/assessment/v2/goal`, withSignature(params), await withRecaptcha());

export const getSignature = () => apiGet(`${API_URL}/assessment/v2/signature`, {});

const withSignature = (params: any) => {
    const signatureData: SIGNATURE = JSON.parse(localStorage.getItem('signatureData') || '{}');

    return { ...params, ...signatureData };
}

const withRecaptcha = async (headers = {}) => {
    const recaptchaToken = await executeRecaptcha();

    return { ...headers, recaptcha: recaptchaToken };
}