import { load } from "recaptcha-v3";

export const executeRecaptcha = async () => {
  console.log("process env", process.env)
  const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';
  const recaptcha = await load(SITE_KEY, {
    useRecaptchaNet: true,
    autoHideBadge: true
  });
  return await recaptcha.execute();
}